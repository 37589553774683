<template>
  <Card class="achivement-warpper">
    <Title>业绩信息</Title>
    <div class="info-card">
      <SubTitle>销售荣誉榜积分</SubTitle>
      <div class="desp mg-8">累计积分：{{ honor_total }}</div>
      <div class="desp mg-16">最近6个月积分统计</div>
      <div class="chart-part h-250 mg-8">
        <column-chart
          v-if="columnConfig.xAxis.data.length > 0"
          :chart-option="columnConfig"
          @click="handleClick"
          height="100%"/>
        <a-empty v-else description="暂无记录"/>
      </div>
    </div>
    <div class="info-card">
      <SubTitle>销售奖励统计</SubTitle>
      <div class="desp mg-8">季度奖励查询</div>
      <div class="reward-inquire mg-8">
        <a-select class="search-select" :default-value="year" @change="handleYearChange" placeholder="请选择年份">
          <a-select-option v-for="item in yearOptions" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
        </a-select>
        <a-select class="search-select" :default-value="quarter" @change="handleQuarterChange" placeholder="请选择季度">
          <a-select-option v-for="item in quarterOptions" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
        </a-select>
        <a-button class="search-btn" @click="getQuarterRewardMoney">查询</a-button>
      </div>

      <div class="quarter-reward-money" v-show="quarter_reward !== ''">季度奖励金额: {{ quarter_reward }}元</div>
      <a-divider />
      <div class="desp" style="margin-top: 16px; margin-bottom: 16px">累计奖励统计</div>
      <div class="chart-part h-280">
        <pie-chart
          v-if="pieData[0].value !== ''"
          height="100%"
          :color="['#DA373E', '#EC6448']"
          textTitle="累计奖励"
          :textContent="info.reward_amount + ''"
          :data="pieData"/>
        <a-empty v-else description="暂无记录"/>
      </div>
    </div>
    <honor-single-month-detail-dialog :shown="shown" :date="date" :user-id="userId" @visible="handleVisible"/>
  </Card>
</template>
<script>
const yearOptions = [];
const currentYear = new Date().getFullYear();
for (let i = 2000; i <= currentYear; i++) {
  yearOptions.push({ label: i, value: i });
}
const quarterOptions = [1, 2, 3, 4].map(item => ({
    label: `${item}季度`,
    value: item
  })
)
import Card from './Card.vue';
import ColumnChart from "@views/studentFiles/components/ColumnChart";
import PieChart from "@views/studentFiles/components/PieChart";
import Title from './Title';
import SubTitle from './SubTitle.vue';
import HonorSingleMonthDetailDialog from "@views/studentFiles/components/HonorSingleMonthDetailDialog";
import {
  getHonorPointManager,
  getHonorSixMonthChartManager,
  getRewardManager,
} from '../api/managerApi';

export default {
  components: {
    Card,
    ColumnChart,
    PieChart,
    Title,
    SubTitle,
    HonorSingleMonthDetailDialog,
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      yearOptions,
      quarterOptions,
      honor_total: '',
      year: undefined,
      quarter: undefined,
      quarter_reward: '',
      columnData: [],
      columnConfig: {
        color: '#EC8153',
        xAxis: {
          type: 'category',
          data: [],
          name: '月份',
          nameGap: 6,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              formatter: '{c}'
            },
          },
        ],
      },
      pieData: [
        { value: 0, name: '已领取' },
        { value: 0, name: '未领取' },
      ],
      date: '',
      shown: false,
      thead: [],
    }
  },
  methods: {
    /**
     * 累计积分
     */
    getPoint() {
      getHonorPointManager({ uid: this.userId }).then(res => {
        this.honor_total = res.data.sum_honor;
      })
    },
    /**
     * 获取柱形图表数据
     */
    getMonthColumnChartData() {
      getHonorSixMonthChartManager({ uid: this.userId }).then((res) => {
        this.columnData = res.meta.day_list;
        this.thead = res.data.thead;
        const xdata = res.data.thead ?? [].map((item) => item);
        const ydata = res.data.list ?? [].map((item) => item);
        this.columnConfig.xAxis.data = xdata;
        this.columnConfig.series[0].data = ydata;
      });
    },
    /**
     * 获取进度奖励金额
     */
    getQuarterRewardMoney() {
      const params = {
        year: this.year,
        quarter: this.quarter,
      };
      getRewardManager({ ...params, uid: this.userId }).then((res) => {
        this.quarter_reward = res.data.sumReward;
      });
    },
    handleClick(params) {
      const index = this.thead.indexOf(params.name);
      this.date = this.columnData[index];
      this.shown = true;
    },
    /**
     * Dialog显示隐藏
     */
    handleVisible(val) {
      this.shown = val;
    },
    handleYearChange(val) {
      this.year = val;
    },
    handleQuarterChange(val) {
      this.quarter = val
    }
  },
  watch: {
    info: {
      handler: function (payload) {
        this.pieData = [
          { value: payload.get_the_amount, name: `已领取${payload.get_the_amount}元` },
          { value: payload.noget_the_amount, name: `未领取${payload.noget_the_amount}元` },
        ];
      },
      immediate: true,
    }
  },
  mounted() {
    this.getPoint();
    this.getMonthColumnChartData();
  }
}
</script>

<style lang="scss" scoped>
.achivement-warpper {
  box-sizing: border-box;
  padding: 16px 20px;
  background: #fff;
  .overview-title {
    font-size: 18px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .info-card {
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .desp {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
    }
    .chart-part {
      background: #FFFFFF;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      border: 1px solid #E9E9E9;
    }
    .h-250 {
      height: 250px;
    }
    .h-280 {
      height: 280px;
    }
    .mg-16 {
      margin-top: 16px;
    }
    .mg-8 {
      margin-top: 8px;
    }
    .reward-inquire {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .search-btn {
        width: 100px;
      }
      .search-select {
        width: 280px;
        margin-right: 16px;
      }
      .search-btn {
        width: 80px;
        background: linear-gradient(90deg, #F42B48 0%, #E50211 100%);
        border-radius: 16px 16px 16px 16px;
        color: #fff;
        opacity: 0.9;
      }
    }
    .quarter-reward-money {
      margin-top: 8px;
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: bold;
      color: #E50211;
    }
  }
}
</style>
