<template>
  <a-select ref="selectRef"
            v-model="assertion"
            :placeholder="placeholder"
            :allow-clear="allowClear"
            :get-popup-container="getContainer"
            @change="$emit('change', assertion)"
            @focus="$emit('focus')"
            @blur="$emit('blur')">
    <a-select-option :value="assertionMap.yes">
      {{ assertionMap.yesText || '是' }}
    </a-select-option>
    <a-select-option :value="assertionMap.no">
      {{ assertionMap.noText || '否' }}
    </a-select-option>
    <a-select-option v-if="assertionMap.notSure" :value="assertionMap.notSure">
      {{ assertionMap.notSureText || '不确定' }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'SelectAssertion',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [String, Number], default: undefined },
    placeholder: { type: String, default: '请选择' },
    allowClear: { type: Boolean, default: true },
    assertionMap: { type: Object, default: () => ({ yes: 'YES', no: 'NO' }) },
    fixedTags: { type: Array, default: () => ['a-form-model-item', 'AFormModelItem'] },
  },
  computed: {
    getContainer() {
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag
            || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent.$el : null) || this.$root?.$el || document.body || null;
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this && this.assertion !== null) {
          this.assertion = val || undefined;
        }
      },
    },
  },
  data() {
    return {
      assertion: undefined,
    };
  },
};
</script>

<style scoped lang="scss">

</style>
