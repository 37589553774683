<template>
  <Card>
    <Title>培训信息</Title>
    <template v-if="works.length > 0">
      <SubTitle>岗位认证</SubTitle>
      <div class="train-list">
        <div
          class="train-list-item"
          v-for="(item, index) in works"
          :key="index">
          <span class="train-list-item-span1">{{item.level_time_fmt}}</span>
          <span>{{item.post_txt}}</span>
          <span class="train-list-item-span2">{{item.level_txt}}</span>
        </div>
      </div>
      <div class="train-line"></div>
    </template>
    <SubTitle>学习统计</SubTitle>
    <div class="train-card">
      <div class="train-card-item">
        <img class="train-card-item-bgd" src="../assets/train/yellow.png">
        <div class="train-card-item-main">
          <span>{{info.courses_completed_num || 0}}门</span>
          <div class="train-card-item-main-href">
            完成课程
          </div>
        </div>
      </div>
      <div class="train-card-item">
        <img class="train-card-item-bgd" src="../assets/train/red.png">
        <div class="train-card-item-main">
          <span>{{info.task_completed_num || 0}}个</span>
          <div class="train-card-item-main-href">
            完成任务
          </div>
        </div>
      </div>
      <div class="train-card-item">
        <img class="train-card-item-bgd" src="../assets/train/purple.png">
        <div class="train-card-item-main">
          <span>{{info.signup_offline_num || 0}}个</span>
          <div class="train-card-item-main-href">
            参加培训
          </div>
        </div>
      </div>
      <div class="train-card-item">
        <img class="train-card-item-bgd" src="../assets/train/blue.png">
        <div
          class="train-card-item-main">
          <span>{{info.join_internal_training_num || 0}}次</span>
          <div class="train-card-item-main-href">
            参加内训
          </div>
        </div>
      </div>
      <div class="train-card-item">
        <img class="train-card-item-bgd" src="../assets/train/cyan.png">
        <div class="train-card-item-main">
          <span>{{info.join_exam_num || 0}}次</span>
          <div class="train-card-item-main-href">
            参加考试
          </div>
        </div>
      </div>
      <div class="train-card-item" style="margin-right:0">
        <img class="train-card-item-bgd" src="../assets/train/pink.png">
        <div class="train-card-item-main">
          <span>{{info.get_certificates_num || 0}}个</span>
          <div class="train-card-item-main-href">
            荣誉证书
          </div>
        </div>
      </div>
    </div>
    <div class="train-line"></div>
    <div class="train-text">累计学习时长：{{toHour(info.study_time || 0)}}h</div>
    <div class="train-text">最近6个月学习时长统计 (h)</div>
    <div id="train-pie" class="train-echart">
      <template v-if="this.option.xAxis.data.length > 0">
        <ColumnChart width="100%" height="100%" :chartOption="option"/>
      </template>
      <template v-else>
        <div class="train-empty">
          <span>暂无数据</span>
        </div>
      </template>
    </div>
    <div class="train-text">累计学习{{info.study_live_course_num || 0}}门直播课</div>
    <div class="train-text">直播时长统计</div>
    <div id="train-pie" class="train-echart">
      <PieChart
        height="100%"
        :color="color"
        textTitle="累计观看时长"
        :textContent="`${toHour(info.live_time)}小时`"
        :data="data"
        :labelWidth="125"/>
    </div>
  </Card>
</template>

<script>
import Card from './Card.vue';
import Title from './Title.vue';
import SubTitle from './SubTitle.vue';
import PieChart from './PieChart.vue';
import ColumnChart from './ColumnChart.vue';
import {
  workExperienceManager,
  getStudyStatisticsInfoManager,
} from '../api/managerApi';

export default {
  name: 'Train',
  components: {
    Card,
    Title,
    SubTitle,
    PieChart,
    ColumnChart,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      color: ['#DA373E', '#EC6448'],
      data: [],
      content: '',
      works: [],
      identity: null,
      title: '',
      option: {
        color: '#EC8153',
        xAxis: {
          name: '月份',
          nameGap: 6,
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'bar',
            barWidth: 50,
            label: {
              show: true,
              position: 'top',
              formatter: '{c} h'
            },
          },
        ],
      },
    };
  },
  watch: {
    info: {
      handler(val) {
        console.log(val);
        this.content = this.toHour(val.live_time);
        const playbackTimeHour = this.toHour(val.live_playback_time);
        const liveingTimeHour = (this.accMul(this.content, 1000)
                                - this.accMul(playbackTimeHour, 1000)) / 1000;
        const playbackTimePercent = this.toPercent(playbackTimeHour);
        const liveingTime = (this.toPercent(this.content) * 1000
                            - this.accMul(playbackTimePercent, 1000)) / 1000;
        this.data = [
          { value: playbackTimeHour, name: `直播回看时长\n${playbackTimeHour}小时${this.toPercent(playbackTimeHour)}%` },
          { value: liveingTimeHour, name: `观看直播时长\n${liveingTimeHour}小时${liveingTime}%` },
        ];
      },
      immediate: true,
    },
  },
  created() {
    this.getWork();
    this.getStudy();
  },
  methods: {
    async getWork() {
      const data = await workExperienceManager({ uid: this.userId });
      if (data.error_code === 0) {
        this.works = data.data;
      }
    },
    async getStudy() {
      const data = await getStudyStatisticsInfoManager({uid: this.userId});
      if (data.error_code === 0) {
        this.option.xAxis.data = data.data.title_key;
        this.option.series[0].data = data.data.data_key.map((x) => this.toHour(x));
      }
    },
    accMul(arg1, arg2) {
      let m = 0; const s1 = arg1.toString(); const
        s2 = arg2.toString();
      try {
        m += s1.split('.')[1].length;
      } catch (e) {
        console.log(e);
      }
      try {
        m += s2.split('.')[1].length;
      } catch (e) {
        console.log(e);
      }
      return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m;
    },
    toPercent(value) {
      if (!this.content) {
        return 0;
      }
      return Math.round((value / this.content) * 100 * 10) / 10;
    },
    toHour(value) {
      return Math.round((value / 3600) * 10) / 10;
    },
  },
};
</script>

<style scoped lang="scss">
.train{
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
    &-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 424px;
      background: #FAFAFA;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #E9E9E9;
      padding: 10px 20px;
      margin-bottom: 16px;
      &-span1{
        width: 30%;
        text-align:left;
        font-family: Helvetica Neue;
      }
      &-span2{
        width: 20%;
        text-align:right;
        font-family: Helvetica Neue;
      }
    }
  }
  &-line{
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.00009999999747378752);
    box-shadow: 0px 0px 0px 1px #DDDDDD;
    border-radius: 0px 0px 0px 0px;
  }
  &-card{
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
    margin-bottom: 16px;
    &-item{
      position: relative;
      width: 131px;
      height: 70px;
      border-radius: 8px 8px 8px 8px;
      &-bgd{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &-main{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: #FFFFFF;
        span{
          font-size: 20px;
          font-weight: 500;
        }
        &-href{
          display: flex;
          align-items: center;
          font-size: 15px;
        }
      }
    }
  }
  &-text{
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
  }
  &-echart{
    height: 250px;
    margin-top: 8px;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #E9E9E9;
  }
  &-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
</style>
