<template>
  <div>
    <a-form-model :model="form"
                  layout="inline"
                  class="query-form ph-20">
      <a-form-model-item label="姓名/电话/身份证号">
        <a-input v-model="form.truename_or_username_or_idcard"
                 :allow-clear="true"
                 placeholder="请输入姓名/电话/身份证号"
                 style="min-width: 220px;"/>
      </a-form-model-item>
      <a-form-model-item label="查询时段">
        <a-range-picker v-model="form.date"
                        format="YYYY-MM-DD"
                        :mode="['date', 'date']"
                        :allow-clear="false"/>
      </a-form-model-item>
      <a-form-model-item label="岗位">
        <SelectPost v-model="form.post_id_str" mode="multiple"/>
      </a-form-model-item>
      <a-form-model-item label="组织机构">
        <SelectOrg v-model="form.org_id_str"/>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" :loading="loading" @click="onSearch">查询</a-button>
      </a-form-model-item>

      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" :loading="downloading" @click="exportExcel">导出</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="pd-card">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id"
               class="table small-cell-table">
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import {
  getStudyList,
  getStudyListDownload,
} from '@/api/studentFiles';

import Pagination, { getPagination, updatePagination } from '@/components/Pagination.vue';
import SelectPost from '@/components/selection/SelectPost.vue';
import SelectOrg from '@/components/selection/SelectOrg.vue';

const columns = [
  {
    width: '80px',
    align: 'center',
    dataIndex: 'truename',
    key: 'truename',
    title: '姓名',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'idcard',
    key: 'idcard',
    title: '学员身份证号',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'username',
    key: 'username',
    title: '电话',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'postname',
    key: 'postname',
    title: '岗位',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'orgname',
    key: 'orgname',
    title: '体验中心',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'course_all_time_sum_hour',
    key: 'course_all_time_sum_hour',
    title: '学习时长（小时）',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'on_live_time_hour',
    key: 'on_live_time_hour',
    title: '直播观看时长（小时）',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'no_live_time_hour',
    key: 'no_live_time_hour',
    title: '直播回看时长（小时）',
  },
];

const form = {
  truename_or_username_or_idcard: '',
  // date: [moment().subtract(89, 'days'), moment()],
  date: [undefined, undefined],
  post_id_str: '',
  org_id_str: '',
};

export default {
  name: 'Study',
  components: {
    Pagination,
    SelectPost,
    SelectOrg,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      form: { ...form },

      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    // this.onSearch();
  },
  methods: {
    reset() {
      this.form = { ...form };
      this.data = [];
      this.pagination = getPagination();
      // this.getData(1, this.pagination.pageSize);
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (this.form.date[0]?.isBefore(this.form.date[1]?.clone().subtract(2, 'months'), 'months')) {
        this.$message.info('查询时段最长为3个月');
        return;
      }

      this.form.page = page || 1;
      this.form.per_page = pageSize || this.pagination.defaultPageSize;

      const params = {
        ...this.form,
        start_day: this.form.date[0]?.format('YYYY-MM-DD') || '',
        end_day: this.form.date[1]?.format('YYYY-MM-DD') || '',
      };
      delete params.date;

      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getStudyList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = (data?.data || []);

      const pagination = data?.meta?.pagination || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },

    exportExcel() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = {
        ...this.form,
        start_day: this.form.date[0]?.format('YYYY-MM-DD'),
        end_day: this.form.date[1]?.format('YYYY-MM-DD'),
        is_download: 1,
      };
      delete params.date;

      getStudyListDownload(params).then(() => {
        this.$message.success('导出成功');
        return true;
      }).catch(() => {
        this.$message.error('导出失败');
        return false;
      }).finally(() => {
        this.downloading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
