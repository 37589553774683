<template>
  <Card class="nav">
    <div v-for="(item) in list"
         :key="item.id"
         class="flex flex-start pointer-cursor nav-item"
         :class="{'active': item.id === activeId}"
         @click="selectItem(item)">
      <img v-if="item.id === activeId" :src="item.activeIcon" alt="" class="mr-15 icon">
      <img v-else :src="item.icon" alt="" class="mr-15 icon">
      <span>{{ item.name }}</span>
    </div>
  </Card>
</template>

<script>
import Card from './Card.vue';

export default {
  name: 'Nav',
  components: {
    Card,
  },
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    value: { type: String, default: 'work' },
    menus: { type: Array, default: () => []},
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.list.map((i) => i.id).includes(val)) {
          this.activeId = val;
        }
      },
    },
    menus: {
      immediate: true,
      handler(val) {
        this.list = val;
      }
    }
  },
  data() {
    return {
      activeId: 'work',
      list: []
    };
  },
  methods: {
    selectItem(item) {
      this.activeId = item.id;
      this.$emit('select', item.id);
      this.$emit('selected', { id: item.id });
    },
  },
};
</script>

<style scoped lang="scss">
.nav {
  width: 280px;
  min-height: 60px;
  padding: 0;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;

  .nav-item {
    width: 100%;
    height: 58px;
    padding-left: 35px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    background-color: #FFFFFF;

    &.active {
      color: #E50211;
      background: rgba(229, 2, 17, 0.05);
    }

    .icon {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
