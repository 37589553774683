<template>
  <div class="student-files-header">
    <div class="float-btn flex pointer-cursor" @click="back">
      <img :src="arrowLeftUrl" alt="" class="arrow-left mr-10">
      <span class="text">返回上级页面</span>
    </div>
    <img class="head-img" :src="userInfo.head_img" />
    <div class="user">
      <div class="info first">
        <div class="line name">
          <span>{{ userInfo.truename }}</span>
          <img v-if="userInfo.sex === 'male'" src="../assets/header/man.png" alt=""/>
          <img v-else-if="userInfo.sex === 'female'" src="../assets/header/woman.png" alt=""/>
        </div>
        <div>{{ userInfo.post }}</div>
        <div class="line">
          <img src="../assets/header/address.png" alt=""/>
          <span>{{ userInfo.area }}</span>
        </div>
      </div>
      <div class="info">
        <div class="line center">
          <span>红旗工龄：{{ userInfo.job_time_length }}</span>
          <span>年      龄：{{ userInfo.age }}</span>
        </div>
        <div>身份证号：{{ userInfo.idcard }}</div>
      </div>
      <div class="info last">
        <div class="line">
          <span>学      历：{{ userInfo.degree_name }}</span>
          <img v-if="!userId" @click="editDegree" class="edit" src="../assets/header/edit-white.png" alt=""/>
        </div>
        <div class="line">
          <span>毕业院校：{{ userInfo.graduate_school }}</span>
          <img v-if="!userId" @click="editSchool" class="edit" src="../assets/header/edit-white.png" alt=""/>
        </div>
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <a-modal
      title="编辑信息"
      okText="提交"
      :visible="show"
      :width="478"
    >
      <template slot="footer">
        <div class="m-footer">
          <a-button class="cancel my-btn" key="back" @click="show = false">
          取消
          </a-button>
          <a-button class="ok my-btn" key="submit" type="primary" :loading="loading" @click="onSubmit">
            提交
          </a-button>
        </div>
      </template>
      <template slot="closeIcon">
        <a-icon @click="show = false" type="close-circle" :style="{ fontSize: '20px', color: '#999' }" />
      </template>
      <div v-if="editType === 'degree'">
        <div class="modal-title">您的学历是</div>
        <a-select size="large" class="modal-input" v-model="degreeId">
          <a-select-option :value="item.id" v-for="item in degreeList" :key="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
      <div v-else>
        <div class="modal-title">您的毕业院校是(请填写学校全称)</div>
        <a-input size="large" class="modal-input" v-model="school" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ossBaseUrl } from '@/Config';
import { getDegreeList } from '../api/api';
import {
  getUserInfoManager,
  upSchoolDegreeManager,
} from '../api/managerApi';

export default {
  name: 'Header',
  props: {
    userId: { type: String, default: '' },
  },
  data() {
    return {
      show: false, // 显示弹窗
      userInfo: {}, // 用户信息
      degreeList: [], // 学历列表
      degreeId: '', // 学历id
      school: '', // 学校
      editType: '', // 修改弹窗类型
      loading: false,
      arrowLeftUrl: ossBaseUrl + 'learning_map/arrow_left.png',
    };
  },

  created() {
    this.getUserInfo();
    this.getDegreeList();
  },

  methods: {
    // 返回
    back() {
      this.$router.back();
    },

    // 获取用户信息
    async getUserInfo() {
      const { data } = await getUserInfoManager({ uid: this.userId });
      this.userInfo = data;
      this.degreeId = data.degree_id || '';
      this.school = data.graduate_school;
    },

    // 获取学历列表
    async getDegreeList() {
      const { data } = await getDegreeList();
      this.degreeList = data.degree;
    },

    editDegree() {
      this.show = true;
      this.editType = 'degree';
    },

    editSchool() {
      this.show = true;
      this.editType = 'school';
    },

    // 提交(修改毕业院校和学历)
    async onSubmit() {
      this.loading = true;
      try {
        await upSchoolDegreeManager({
          uid: this.userId,
          degree_id: this.degreeId,
          graduate_school: this.school,
        });
        this.show = false;
        this.getUserInfo();
        this.$message.success('修改成功');
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.float-btn {
  position: absolute;
  top: 22px;
  right: 30px;
  z-index: 11;
  min-width: 179px;
  height: 35px;
  padding-left: 19px;
  padding-right: 19px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
  zoom: 0.8;

  .arrow-left {
    width: 11px;
    height: 19px;
  }

  .text {
    font-size: 20px;
    color: #333333;
  }
}

.m-footer {
  display: flex;
  justify-content: center;
  padding: 18px 0;

  .cancel {
    width: 130px;
    height: 36px;
    border-radius: 50px;
    border: 1px solid #FF5A52;
    font-size: 18px;
    color: #FF5A52;
    margin-right: 15px;
  }

  .ok {
    width: 130px;
    height: 36px;
    background: #FF5A52;
    border-radius: 50px;
    font-size: 18px;
    color: #fff;
    border: 0;
  }
}

.student-files-header {
  height: 150px;
  min-width: 1200px;
  background-image: url("../assets/header/head-bg.png");
  background-size: 100% auto;
  display: flex;
  align-items: center;
  color: #fff;

  .head-img {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    border: 2px solid #F9D899;
    margin: 0 20px 0 40px;
  }

  .user {
    display: flex;
    align-items: flex-end;
    min-width: 0;

    .info {
      margin-right: 64px;
      min-width: 0;
      flex-shrink: 0;

      div {
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
      }

      .line {
        display: flex;
        align-items: center;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-all;
        }

        img {
          height: 14px;
          margin-right: 4px;
        }

        .edit {
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .center {
        justify-content: space-between;
      }

      .name {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;

        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }
    }

    .first {
      max-width: 33.33%;
    }

    .last {
      flex: 1;
    }
  }
}

.modal-title {
  font-size: 16px;
  color: #000;
  line-height: 20px;
}

.modal-input {
  width: 100%;
  margin-top: 10px;
}
</style>
