<template>
 <Card class="interactive">
    <Title>互动信息</Title>
    <SubTitle>平台积分</SubTitle>
    <div class="integration-box">
      <div class="integration-box-left">
        <div class="integration-box-num">{{ info.remaining_points }}</div>
        <div class="score-text">当前剩余积分</div>
      </div>
      <div class="integration-box-line"></div>
      <div class="integration-box-right">
        <div class="get-score-box">
          <div class="right-num">{{ info.accumulate_points }}</div>
          <div class="score-text">累计获取积分</div>
        </div>
        <div>
          <div class="right-num">{{ info.cost_points }}</div>
          <div class="score-text">累计花费积分</div>
        </div>
      </div>
    </div>
    <SubTitle>社区统计</SubTitle>
    <div class="statistics">
      <div class="statistics-box-one statistics-bottom">
        <div @click="toPageOnLineCom(3)" class="statistics-course-cyan">
          <div class="statistics-num">{{ info.find_num }}个</div>
          <div class="statistics-text">分享内容 </div>
        </div>
        <div @click="toPageOnLineCom(1)" class="statistics-course-pink">
          <div class="statistics-num">{{ info.community_questions_num }}个</div>
          <div class="statistics-text">提出问题 </div>
        </div>
        <div @click="toPageOnLineCom(2)" class="statistics-course-yellow">
          <div class="statistics-num">{{ info.community_answer_num  }}个</div>
          <div class="statistics-text">回答问题 </div>
        </div>
      </div>
      <div class="statistics-box-one statistics-bottom-two">
        <div class="statistics-course">
          <div class="statistics-num">{{ info.attention_course_num }}个</div>
          <div class="statistics-text">关注课程 </div>
        </div>
        <div class="statistics-course-red">
          <div class="statistics-num">{{ info.attention_find_num }}个</div>
          <div class="statistics-text">关注分享 </div>
        </div>
        <div class="statistics-course-purple">
          <div class="statistics-num">
            {{ info.attention_community_answer_num }}个
          </div>
          <div class="statistics-text">关注问答 </div>
        </div>
        <div class="statistics-course-blue">
          <div class="statistics-num">{{ info.attention_user_num }}个</div>
          <div class="statistics-text">关注用户 </div>
        </div>
      </div>
    </div>
    <SubTitle>评论统计</SubTitle>
    <div class="chart">
      <PieChart
        :color="color"
        :data="data"
        textTitle="累计发布评论"
        :textContent="content"
      />
    </div>
    <CodeModule
      :open="open"
      title="关注"
      :url="info.download_qr_url"
      @cancel="open = false"
    />
  </Card>
</template>

<script>
import Card from './Card.vue';
import PieChart from "./PieChart.vue";
// import CodeModule from "./CodeModule.vue";
import Title from './Title.vue';
import SubTitle from './SubTitle.vue';
export default {
  components: {
    Card,
    PieChart,
    // CodeModule,
    Title,
    SubTitle
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: String,
      default: '',
    },
  },
  watch: {
    info: {
      handler(val) {
        this.data = [
          {
            value: val.activity_comments_num,
            name: `活动评论 \n${val.activity_comments_num}条${this.toPercent(
              val.activity_comments_num
            )}%`,
          },
          {
            value: val.course_comments_num,
            name: `课程评论 \n${val.course_comments_num}条${this.toPercent(
              val.course_comments_num
            )}%`,
          },
          {
            value: val.community_comments_num,
            name: `问答评论 \n${val.community_comments_num}条${this.toPercent(
              val.community_comments_num
            )}%`,
          },
          {
            value: val.find_comments_num,
            name: `分享评论 \n${val.find_comments_num}条${this.toPercent(
              val.find_comments_num
            )}%`,
          },
        ];
        this.content = `${val.comments_num}条`;
      },
      immediate: true,
    },
  },
  data() {
    return {
      data: [],
      color: ["#DA373E", "#EC6448", "#EC8153", "#ECB153"],
      content: "",
      open: false,
    };
  },
  methods: {
    toPageOnLineCom() {
      // this.$router.push({
      //   path: "/index/onlineCommunity/myQA",
      //   query: {
      //     isShowBack: 1,
      //     type,
      //   },
      // });
    },
    toPercent(value) {
      if (value !== 0) {
        return Math.round((value / this.info.comments_num) * 100 * 10) / 10;
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
@mixin statistics-box($width) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: $width;
  height: 67px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.interactive {
  //width: 904px;
  // height: calc(100vh - 206px);
  // overflow-y: auto;
  //background-color: #fff;
  //padding: 16px;
  &-title {
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333;
    margin-bottom: 16px;
  }
  .integration-box {
    // width: 686px;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 11px;
    margin-bottom: 11px;
    background: linear-gradient(270deg, #d3b58e 0%, #cda066 100%);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    &-num {
      font-size: 30px;
      color: #fff;
    }
    &-line {
      width: 2px;
      height: 74px;
      background: #fff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    &-right {
      display: flex;
      text-align: center;
      .get-score-box {
        margin-right: 114px;
      }
      .right-num {
        font-size: 20px;
        color: #fff;
        margin-bottom: 11px;
      }
    }
  }
  .score-text {
    font-size: 15px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    opacity: 0.5;
  }
  .statistics-title {
    margin: 30px 0;
  }
  .statistics {
    margin-top: 11px;
    cursor: pointer;
    &-box-one {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .statistics-bottom {
      margin-bottom: 28px;
    }
    .statistics-bottom-two {
      margin-bottom: 11px;
    }

    &-course-cyan {
      @include statistics-box(275px);
      background-image: url("../assets/interactions/cyan.png");
    }
    &-course-pink {
      @include statistics-box(275px);
      background-image: url("../assets/interactions/pink.png");
    }
    &-course-yellow {
      @include statistics-box(275px);
      background-image: url("../assets/interactions/yellow1.png");
    }
    &-course {
      @include statistics-box(203px);
      background-image: url("../assets/interactions/yellow.png");
    }
    &-course-red {
      @include statistics-box(203px);
      background-image: url("../assets/interactions/red.png");
    }
    &-course-purple {
      @include statistics-box(203px);
      background-image: url("../assets/interactions/purple.png");
    }
    &-course-blue {
      @include statistics-box(203px);
      background-image: url("../assets/interactions/blue.png");
    }
    &-num {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fff;
    }
    &-text {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff;
    }
  }
  .chart {
    margin-top: 11px;
    border: 1px solid #e9e9e9;
  }
}
</style>
