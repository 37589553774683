<template>
  <a-select ref="selectRef"
            v-model="selectionValue"
            placeholder="请选择汽车品牌"
            :get-popup-container="getContainer"
            @change="onChange"
            @focus="$emit('focus')"
            @blur="$emit('blur')">
    <a-select-option v-for="(item) in list"
                     :key="item.id"
                     :value="item.id">{{ item.title }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectBrand',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [String, Number], default: undefined },
    fixedTags: { type: Array, default: () => ['a-form-model-item', 'AFormModelItem'] },
  },
  computed: {
    ...mapState({
      list: state => state.Selection.brandList,
    }),
    getContainer() {
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag
            || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent.$el : null) || this.$root?.$el || document.body || null;
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this && this.selectionValue !== null) {
          this.selectionValue = +val || val || undefined;
        }
      },
    },
  },
  data() {
    return {
      selectionValue: undefined,
    };
  },
  created() {
    this.$store.dispatch('Selection/getBrandList');
  },
  methods: {
    onChange() {
      const [item] = this.list.filter((i) => i.id === this.selectionValue);

      this.$emit('change', this.selectionValue);
      this.$emit('changed', item);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
