<template>
  <a-modal :title="title"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="false"
           :width="600"
           :centered="true"
           :destroy-on-close="true"
           wrap-class-name="student-file-modal-wrapper"
           class="modal"
           ok-text="提交"
           :after-close="closed"
           @ok="confirm"
           @cancel="close()">
    <a-form-model ref="formModalRef"
                  :model="form"
                  label-align="right"
                  layout="horizontal"
                  :label-col="{span: 6}"
                  :wrapper-col="{span: 18}"
                  class="pd-card">

      <a-form-model-item label="岗位级别"
                         class="form-item">
        <template v-for="(item, index) in form.posts">
          <a-input v-model="item.post_name"
                   :key="'post' + index"
                   :disabled="true"
                   :style="{width: item.level_name ? '45%' : '100%'}"/>
          <a-input v-if="item.level_name"
                   v-model="item.level_name"
                   :key="'level' + index"
                   :disabled="true"
                   style="width: 50%; margin-left: 5%;"/>
        </template>
      </a-form-model-item>

      <a-form-model-item label="组织机构"
                         class="form-item">
        <template v-for="(item, index) in form.orgs">
          <a-input v-model="item.org_title"
                   :key="'org' + index"
                   :disabled="true"
                   :style="{width: item.regional_org_name ? '45%' : '100%'}"/>
          <a-input v-if="item.regional_org_name"
                   v-model="item.regional_org_name"
                   :key="'shop' + index"
                   :disabled="true"
                   style="width: 50%; margin-left: 5%;"/>
        </template>
      </a-form-model-item>

      <a-form-model-item label="身份证号"
                         prop="idcard"
                         class="form-item">
        <a-input v-model="form.idcard" :disabled="true" placeholder="请输入身份证号"/>
      </a-form-model-item>

      <a-form-model-item label="学历"
                         prop="degree_id"
                         class="form-item">
        <SelectDegree v-model="form.degree_id"/>
      </a-form-model-item>

      <a-form-model-item label="毕业院校"
                         prop="graduate_school"
                         class="form-item">
        <a-input v-model="form.graduate_school" :allow-clear="true" placeholder="请输入毕业院校"/>
      </a-form-model-item>

      <a-form-model-item label="进入汽车行业时间"
                         prop="in_automobile_industry_day"
                         class="form-item mt-30 mb-20">
        <a-month-picker v-model="form.in_automobile_industry_day"
                        format="YYYY/MM"
                        :allow-clear="false"
                        placeholder="请选择进入汽车行业时间"
                        class="full-width"/>
      </a-form-model-item>

    </a-form-model>

    <div class="flex flex-start mb-10">
      <span class="sub-text">豪华品牌经验</span>
      <a-button type="primary" @click="addLuxury">添加</a-button>
    </div>

    <div v-for="(luxury) in luxuryFormList"
         :key="luxury.id"
         class="flex space-between experience-card">
      <a-form-model ref="brandFormModalRef"
                    :model="luxury"
                    label-align="right"
                    layout="horizontal"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}"
                    class="pd-card flex-grow secondary-form">
        <a-form-model-item label="从业品牌"
                           prop="brand"
                           class="form-item">
          <SelectBrand v-model="luxury.brand"/>
        </a-form-model-item>
        <a-form-model-item label="从业时间"
                           prop="in_automobile_industry_day"
                           class="form-item">
          <a-range-picker v-model="luxury.date"
                          format="YYYY/MM"
                          :mode="['month', 'month']"
                          :allow-clear="false"
                          class="full-width"
                          @panelChange="rangePanelChange($event, luxury, 'date')"/>
        </a-form-model-item>
        <a-form-model-item label="任职岗位"
                           prop="post"
                           class="form-item">
          <!-- <SelectPost v-model="luxury.post_id"/> -->
          <a-input v-model="luxury.post" placeholder="填写任职岗位"/>
        </a-form-model-item>
      </a-form-model>

      <div class="flex-none ml-40">
        <a-button type="link" class="delete-btn" @click="deleteLuxury(luxury)">
          <a-icon type="delete" style="font-size: 18px;"/>
        </a-button>
      </div>
    </div>
    <a-empty v-if="!loading && (!luxuryFormList || !luxuryFormList.length)"
             description=""
             class="empty"/>

    <div class="flex flex-start mb-10">
      <span class="sub-text">新能源经验</span>
      <a-button type="primary" @click="addNewEnergy">添加</a-button>
    </div>

    <div v-for="(newEnergy) in newEnergyFormList"
         :key="newEnergy.id"
         class="flex space-between experience-card">
      <a-form-model ref="brandFormModalRef"
                    :model="newEnergy"
                    label-align="right"
                    layout="horizontal"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}"
                    class="pd-card flex-grow secondary-form">
        <a-form-model-item label="从业品牌"
                           prop="brand"
                           class="form-item">
          <!-- <SelectBrand v-model="newEnergy.brand_id"/> -->
          <a-input v-model="newEnergy.brand" placeholder="填写从业品牌"/>
        </a-form-model-item>
        <a-form-model-item label="从业时间"
                           prop="in_automobile_industry_day"
                           class="form-item">
          <a-range-picker v-model="newEnergy.date"
                          format="YYYY/MM"
                          :mode="['month', 'month']"
                          :allow-clear="false"
                          class="full-width"
                          @panelChange="rangePanelChange($event, newEnergy, 'date')"/>
        </a-form-model-item>
        <a-form-model-item label="任职岗位"
                           prop="post"
                           class="form-item">
          <!-- <SelectPost v-model="newEnergy.post_id"/> -->
          <a-input v-model="newEnergy.post" placeholder="填写任职岗位"/>
        </a-form-model-item>
      </a-form-model>

      <div class="flex-none ml-40">
        <a-button type="link" class="delete-btn" @click="deleteNewEnergy(newEnergy)">
          <a-icon type="delete" style="font-size: 18px;"/>
        </a-button>
      </div>
    </div>
    <a-empty v-if="!loading && (!newEnergyFormList || !newEnergyFormList.length)"
             description=""
             class="empty"/>
  </a-modal>
</template>

<script>
import moment from 'moment';
import {
  getStudentFile,
  saveStudentFile,
} from '@/api/studentFiles';

import SelectBrand from '@/components/selection/SelectBrand.vue';
import SelectDegree from '@/components/selection/SelectDegree.vue';
// import SelectPost from '@/components/selection/SelectPost';

const form = {
  // 不可修改
  posts: [],
  orgs: [],
  idcard: '',

  // 可修改
  degree_id: '',
  graduate_school: '',
  in_automobile_industry_day: undefined,
};

export default {
  name: 'StudentFileModal',
  components: {
    SelectBrand,
    SelectDegree,
    // SelectPost,
  },
  data() {
    return {
      shown: false,
      loading: false,

      tempId: -1,
      id: '',
      title: '',
      form: { ...form },
      luxuryFormList: [],
      newEnergyFormList: [],
    };
  },
  methods: {
    show({ id, truename } = {}) {
      this.id = id;
      this.title = `${truename}的学员档案`;
      this.shown = true;

      this.getData();
    },
    close() {
      this.shown = false;
    },
    closed() {
      this.id = '';
      this.title = '';
      this.tempId = -1;
      this.form = { ...form };
      this.luxuryFormList = [];
      this.newEnergyFormList = [];
    },

    // 获取数据
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getStudentFile({ user_id: this.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取学员档案信息失败，请重试');
        return;
      }
      const result = data?.data || {};
      const meta = data?.meta || {};
      this.form.posts = meta.post_level || [];
      this.form.orgs = meta.org_info || [];
      this.form.idcard = meta.idcard;
      this.form.degree_id = meta.degree_id;
      this.form.graduate_school = meta.graduate_school;
      this.form.in_automobile_industry_day =
        meta.in_automobile_industry_day
          ? moment(meta.in_automobile_industry_day, 'YYYY/MM')
          : undefined;

      this.luxuryFormList = this.parseListDate(result.luxury || []);
      this.newEnergyFormList = this.parseListDate(result.new_energy || []);
    },

    // 转换日期
    parseListDate(list) {
      list.forEach((item) => {
        item.date = [
          moment(item.start_day, 'YYYY/MM'),
          moment(item.end_day, 'YYYY/MM'),
        ];
      });
      return list;
    },
    getFormatter(date = '') {
      /^(\d{2,4})(\D)(\d{1,2})((\D)(\d{1,2})){0,1}/g.test(date);
      const year = this.getRepeatChar('Y', RegExp.$1.length || 0);
      const month = this.getRepeatChar('M', RegExp.$3?.length || 0);
      const day = this.getRepeatChar('D', RegExp.$6?.length || 0);
      return {
        // 后端返回的格式
        formatter: `${year}${RegExp.$2}${month}${day ? RegExp.$5 : ''}${day ? day : ''}`,
        // 短横线分割的格式
        formatter2: `${year}-${month}${day ? '-' : ''}${day ? day : ''}`,
        // 短横线分割的日期
        formatted: `${RegExp.$1}-${RegExp.$3}${RegExp.$6 ? '-' : ''}${RegExp.$6 ? RegExp.$6 : ''}`,
      };
    },
    getRepeatChar(char, length) {
      if (length <= 0) {
        return '';
      }
      let result = '';
      for (let i = 0; i < length; i++) {
        result += char;
      }
      return result;
    },

    confirm() {
      this.save();
    },

    async save() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        user_id: this.id,
        ...this.form,
        in_automobile_industry_day: this.form.in_automobile_industry_day?.format('YYYY/MM') || '',
        luxury_exp: this.getExperienceStr(this.luxuryFormList),
        new_energy: this.getExperienceStr(this.newEnergyFormList),
      };

      delete params.post;
      delete params.areaShop;
      delete params.idcard;

      const data = await saveStudentFile(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败，请重试');
        return;
      }
      this.$message.success(data?.message || '保存成功');
      this.close();
    },
    getExperienceStr(list) {
      const arr = list.map((item) => {
        const parsed = {
          brand: item.brand,
          post: item.post,
          start_day: item.date[0]?.format('YYYY/MM'),
          end_day: item.date[1]?.format('YYYY/MM'),
        };
        if (item.id > 0) {
          parsed.id = item.id;
        } else if (parsed.brand || parsed.post || parsed.start_day || parsed.end_day) {
          parsed.id = '';
        }

        return parsed;
      });

      return arr?.length ? JSON.stringify(arr) : '';
    },

    // 月份范围选择变化
    rangePanelChange(value, record, field) {
      field = field || 'date';
      record[field] = value || [undefined, undefined];
    },

    // 添加经验
    addLuxury() {
      this.addExperience(undefined, this.luxuryFormList);
    },
    addNewEnergy() {
      this.addExperience(undefined, this.newEnergyFormList);
    },
    addExperience(record, list) {
      record = record || {
        brand: undefined,
        date: [undefined, undefined],
        start_day: undefined,
        end_day: undefined,
        post: undefined,
      };
      this.tempId -= 1;
      record.id = this.tempId;
      list.push(record);
    },

    // 删除经验
    deleteLuxury(record) {
      this.deleteExperience(record, this.luxuryFormList, '豪华品牌经验');
    },
    deleteNewEnergy(record) {
      this.deleteExperience(record, this.newEnergyFormList, '新能源经验');
    },
    deleteExperience(record, list, text) {
      this.$confirm({
        title: '提示',
        content: `确认删除${text}？`,
        onOk: () => this.confirmDeleteExperience(record, list),
      });
    },
    confirmDeleteExperience(record, list) {
      const index = list.findIndex((i) => i.id === record.id);
      if (index > -1) {
        list.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-item {
  margin-bottom: 5px;

  &.mb-20 {
    margin-bottom: 20px;
  }
}

.sub-text {
  width: 25%;
  padding-right: 10px;
  text-align: right;
  font-size: 16px;
  color: #000000;
}

.experience-card {
  width: 90%;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #E5E5E5;
}
</style>

<style lang="scss">
.student-file-modal-wrapper .ant-modal .ant-modal-content .ant-modal-body {
  overflow-y: auto;
  max-height: 70vh;
}
</style>
