import request from '@/utils/Request';

// 获取用户信息
export function getUserInfoManager(params) {
  return request({
    url: '/studentfiles/admin/studentfiles/userBasicInfo',
    method: 'GET',
    params,
  });
}

// 获取学历列表
export function getDegreeListManager(params) {
  return request({
    url: '/public/select/degree',
    method: 'GET',
    params,
  });
}

// 修改毕业院校和学历
export function upSchoolDegreeManager(data) {
  return request({
    url: '/studentfiles/admin/studentfiles/upSchoolDegree',
    method: 'POST',
    data,
  });
}

// 获取红旗工作经历
export function getWorkExperienceManager(params) {
  return request({
    url: '/studentfiles/admin/studentfiles/rfWorkExperience',
    method: 'GET',
    params,
  });
}

// 获取汽车行业经历
export function getCarExperienceManager(params) {
  return request({
    url: '/studentfiles/admin/studentfiles/carIndustryExperience',
    method: 'GET',
    params,
  });
}

// 获取品牌列表
export function getBrandManager() {
  return request({
    url: '/public/select/carBrand',
    method: 'GET',
  });
}

// 获取任职岗位
export function getPostManager() {
  return request({
    url: '/manage/select/jobs',
    method: 'GET',
  });
}

// 修改用户汽车行业经历
export function editCarExperienceManager(data) {
  return request({
    url: '/studentfiles/admin/studentfiles/updCarIndustryExperience',
    method: 'POST',
    data,
  });
}

// 红旗工作经历
export function workExperienceManager(params) {
  return request({
    url: '/studentfiles/admin/studentfiles/guardAuthInfo',
    method: 'get',
    params,
  });
}

// 统计信息
export function getStatisticsInfoManager(params) {
  return request({
    url: '/studentfiles/admin/user/statdata',
    method: 'get',
    params,
  });
}

// 6月统计图表
export function getSixmonthechartsManager() {
  return request({
    url: '/studentfiles/admin/user/studytime/sixmonthecharts',
    method: 'get',
  });
}

// 奖励查询
export function getRewardManager(params) {
  return request({
    url: '/studentfiles/admin/studentfiles/salesReward',
    method: 'get',
    params,
  });
}

// 6个月学习统计信息
export function getStudyStatisticsInfoManager(params) {
  return request({
    url: '/studentfiles/admin/user/studytime/sixmonthecharts',
    method: 'get',
    params,
  });
}

// 内训身份
export function getTrainIdentityManager(params) {
  return request({
    url: '/internaltraining/admin/user/Identity',
    method: 'get',
    params,
  });
}

// e旗学app下载二维码
export function getCodeUrlManager(params) {
  return request({
    url: '/public/conf/index',
    method: 'get',
    params,
  });
}

// 荣耀积分获取
export function getHonorPointManager(params) {
  return request({
    url: '/studentfiles/admin/studentfiles/honorStatistics',
    method: 'get',
    params,
  });
}

// 荣耀六月累计数据
// 荣耀积分6月统计
export function getHonorSixMonthChartManager(params) {
  return request({
    url: '/studentfiles/admin/studentfiles/honorStatisticsInfo',
    method: 'get',
    params,
  });
}

// 荣耀单月数据详情
export function getHonorMonthInfoManager(date) {
  return request({
    url: `/studentfiles/admin/studentfiles/honorStatisticsInfoMonth?date=${date}`,
    method: 'get',
  });
}
