<template>
  <div class="pie-chart" :style="{ height: height, width: width }" />
</template>
<script>
import * as echarts from 'echarts';
// import resize from '@/views/dashboard/mixins/resize'

require('echarts/theme/macarons');

export default {
  // mixins: [resize],
  props: {
    height: {
      type: String,
      default: '250px',
    },
    width: {
      type: String,
      default: '100%',
    },
    data: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Array,
      default: () => [],
    },
    textTitle: {
      type: String,
      default: '',
    },
    textContent: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: Number,
      default: 90,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    data: {
      handler() {
        this.$nextTick(() => {
          this.initChart();
        })
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.chart.setOption({
        color: this.color,
        series: [
          {
            name: '饼图',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            emphasis: {
              scale: false,
            },
            data: this.data,
          },
        ],
        graphic: {
          // 添加原生图形元素组件
          elements: [
            {
              type: 'text', // 组件类型
              left: 'center', // 定位
              top: '43%', // 定位
              style: {
                // 样式
                text: this.textTitle, // 文字
                textAlign: 'center', // 定位
                width: 30,
                height: 30,
                fill: '#333333FF', // 字体颜色
              },
            },
            {
              type: 'text',
              left: 'center',
              top: '52%',
              style: {
                text: this.textContent,
                textAlign: 'center',
                width: 30,
                height: 25,
                fill: '#333333FF',
              },
            },
          ],
        },
      });
    },
  },
};
</script>
