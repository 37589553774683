<template>
  <div class="full-page student-files">
    <Header :user-id="userId" class="mb-15 student-files-header"/>
    <Nav ref="navRef"
         v-model="activeId"
         :menus="menus"
         class="flex-none mr-15 nav"
         @selected="scrollToTab"/>

    <div ref="scrollRef"
         class="full-height student-files-main"
         @scroll="onScroll">
      <div class="student-files-main-inner">
        <Work ref="workRef" :info="info" :user-id="userId"/>
        <achievement ref="achievementRef" :info="info" :user-id="userId" v-if="is_show_achievement"/>
        <Train ref="trainRef" :info="info" :user-id="userId"/>
        <Interaction ref="interactionRef" :info="info" :user-id="userId"/>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeUrl } from './api/api';
import { getStatisticsInfoManager, getHonorSixMonthChartManager } from './api/managerApi';

import Header from './components/Header.vue';
import Nav from './components/Nav.vue';

import Work from './components/Work.vue';
import Interaction from './components/Interactions.vue';
import Train from './components/Train.vue';
import achievement from './components/Achivement.vue';

export default {
  name: 'File',
  components: {
    Header,
    Nav,
    Work,
    Train,
    Interaction,
    achievement,
  },
  data() {
    return {
      is_show_achievement: true,
      menus: [
        {
          id: 'work',
          name: '工作信息',
          icon: require('./assets/nav/work.png'),
          activeIcon: require('./assets/nav/work_active.png'),
        },
        {
          id: 'achievement',
          name: '业绩信息',
          icon: require('./assets/nav/achievement.png'),
          activeIcon: require('./assets/nav/achievement_active.png'),
        },
        {
          id: 'train',
          name: '培训信息',
          icon: require('./assets/nav/train.png'),
          activeIcon: require('./assets/nav/train_active.png'),
        },
        {
          id: 'interaction',
          name: '互动信息',
          icon: require('./assets/nav/interaction.png'),
          activeIcon: require('./assets/nav/interaction_active.png'),
        },
      ],
      scrollTimer: null,
      tabNavigateScrolling: false,

      userId: '',
      activeId: 'work',
      info: {},
    };
  },
  created() {
    this.userId = this.$route.query.userId || '';
  },
  mounted() {
    this.init();
    this.checkAchievementPagePermission();
  },
  methods: {
    async init() {
      const ret = await getStatisticsInfoManager({ uid: this.userId });
      if (!ret || ret.error_code) {
        this.$message(ret?.message || '获取失败');
        return;
      }
      this.info = ret.data;
      this.getCode();
    },
    async checkAchievementPagePermission() {
      const { meta } = await getHonorSixMonthChartManager({ uid: this.userId });
      if (meta.is_show_echars === false) {
        this.menus = this.menus.filter(item => item.id !== 'achievement');
      }
      this.is_show_achievement = meta.is_show_echars;
    },
    async getCode() {
      const res = await getCodeUrl();
      this.info.download_qr_url = res?.data?.download_qr_url;
    },
    /**
     * 滚动到当前选中 tab
     */
    scrollToTab() {
      const scrollEl = this.$refs.scrollRef || undefined;
      const el = this.$refs[this.activeId + 'Ref']?.$el || undefined;

      if (!scrollEl || !el) {
        return;
      }

      this.scrollToY(scrollEl, +el.offsetTop - 15, 260);
    },

    scrollToY(el, posY, duration) {
      if (!el) {
        return;
      }

      this.tabNavigateScrolling = true;

      const orgY = el.scrollTop;
      let y = orgY;

      const stepTime = 8;
      const durationTime = !(duration % stepTime) ? (duration + 1) : duration;
      const step = Math.abs(posY - orgY) / (durationTime / stepTime);
      const timer = setInterval(() => {
        if (Math.abs(posY - y) <= step) {
          clearInterval(timer);
          this.tabNavigateScrolling = false;
          return;
        }

        y += (posY - orgY > 0 ? step : -step);
        el.scrollTo(0, y);
      }, stepTime);
    },

    onScroll() {
      if (this.tabNavigateScrolling) {
        return;
      }
      if (this.scrollTimer) {
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
      }
      this.locate();
      this.scrollTimer = setTimeout(() => {
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.locate();
      }, 180);
    },

    locate() {
      const scrollTop = this.$refs.scrollRef.scrollTop || 0;
      if (!scrollTop) {
        return;
      }
      const refs = [
        this.$refs.workRef,
        this.$refs.trainRef,
        this.$refs.interactionRef,
      ];
      if (this.is_show_achievement) {
        refs.push(this.$refs.achievementRef);
      }
      refs.forEach((ref) => {
        if (Math.abs(ref.$el.offsetTop - scrollTop) < 120) {
          this.activeId = ref.$vnode?.componentOptions?.tag?.toLowerCase() || '';
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.student-files {
  position: relative;
  overflow-y: hidden !important;
  background-color: #f1f1f1;

  .nav {
    position: absolute;
    top: 165px;
    left: calc(50% - 600px);
    z-index: 2;
    height: calc(100% - 165px - 16px);
  }
}

.student-files-header {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.student-files-main {
  overflow-y: auto !important;
  padding-top: 165px;
}

.student-files-main-inner {
  position: relative;
  left: calc(50% - 295px);
  width: calc(1200px - 295px);
}
</style>
