<template>
  <a-select ref="selectRef"
            v-model="selectionValue"
            placeholder="请选择岗位"
            :mode="selectionMode"
            :show-search="true"
            :filter-option="filterOption"
            :get-popup-container="getContainer"
            :allow-clear="true"
            :disabled="disabled"
            class="select"
            @change="onChange"
            @focus="$emit('focus')"
            @blur="$emit('blur')">
    <a-select-option v-for="(item) in list"
                     :key="item.id"
                     :value="item.id">
      <a-tooltip v-if="showTooltip" :title="item.name" placement="left">{{ item.name }}</a-tooltip>
      <span v-else>{{ item.name }}</span>
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectPost',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [String, Number], default: '' },
    mode: {
      type: String,
      default: 'single',
      validator: (val) => ['single', 'multiple'].includes(val),
    },
    showTooltip: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    fixedTags: { type: Array, default: () => ['a-form-model-item', 'AFormModelItem'] },
  },
  computed: {
    ...mapState({
      list: state => state.Selection.postLevelList,
    }),
    selectionMode() {
      switch (this.mode) {
        case 'single':
          return 'default';
        case 'multiple':
          return 'multiple';
        default:
          return 'default';
      }
    },
    getContainer() {
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag
            || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent.$el : null) || this.$root?.$el || document.body || null;
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this && this.selectionValue !== null) {
          this.setPost(val);
        }
      },
    },
  },
  data() {
    return {
      selectionValue: undefined,
    };
  },
  created() {
    this.$store.dispatch('Selection/getPostLevelList');
  },
  methods: {
    filterOption(value, option) {
      const text = (
        this.showTooltip
          ? option.componentOptions.children[0]?.componentOptions?.children[0]?.text
          : option.componentOptions.children[0]?.children[0]?.text
      ) || '';
      return text.indexOf(value) > -1;
    },

    setPost(value) {
      value = value || this.value;
      const posts = `${value || ''}`.split(',').map((i) => +i).filter((i) => !!i);

      if (this.mode === 'single') {
        this.selectionValue = posts[0] || undefined;
      }
      if (this.mode === 'multiple') {
        this.selectionValue = [...posts];
      }
    },

    onChange() {
      let evt = '';

      if (this.mode === 'single') {
        evt = this.selectionValue ? `${this.selectionValue}` : undefined;
      }
      if (this.mode === 'multiple') {
        evt = this.selectionValue?.length ? this.selectionValue.join(',') : undefined;
      }

      this.$emit('change', evt);
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 180px;
}
</style>
