<template>
  <div class="title">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Title',
};
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: #333333;
}
</style>
