<template>
  <a-select ref="selectRef"
            v-model="gender"
            placeholder="请选择性别"
            :get-popup-container="getContainer"
            @change="$emit('change', gender)"
            @focus="$emit('focus')"
            @blur="$emit('blur')">
    <a-select-option value="male">男</a-select-option>
    <a-select-option value="female">女</a-select-option>
    <a-select-option value="secret">未知</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'SelectGender',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: undefined },
    fixedTags: { type: Array, default: () => ['a-form-model-item', 'AFormModelItem'] },
  },
  computed: {
    getContainer() {
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag
            || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent.$el : null) || this.$root?.$el || document.body || null;
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this && this.gender !== null) {
          this.gender = val || undefined;
        }
      },
    },
  },
  data() {
    return {
      gender: undefined,
    };
  },
};
</script>

<style scoped lang="scss">

</style>
