<template>
  <div class="select-wrapper">
    <a-select v-model="selectedIds"
              ref="selectRef"
              :open="false"
              :allow-clear="false"
              :mode="'multiple'"
              :disabled="disabled"
              :show-arrow="false"
              placeholder="请选择组织机构"
              class="select"
              @focus="showModal">
      <a-select-option :value="0">请选择组织机构</a-select-option>
      <a-select-option v-for="item in selectedData" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>

    <a-icon theme="filled"
            type="close-circle"
            class="clear-btn"
            :class="{'hidden': !selectedData || !selectedData.length}"
            @click="clear"/>

    <SelectOrgModal v-if="modalShown" :default-selected-ids="selectedIdsString"
                    @confirm="select" @close="closeModal"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SelectOrgModal from '@components/SelectOrgModal';
import Common from '@/utils/Common';

export default {
  name: 'SelectOrg',
  components: { SelectOrgModal },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      options: state => state.Common.orgTree,
    }),
    selectedIdsString() {
      return (this.selectedIds || []).join(',');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
    options() {
      this.setDefault();
    },
  },
  data() {
    return {
      modalShown: false,
      selectedData: [],
      selectedIds: [],
    };
  },
  methods: {
    setDefault() {
      if (!this.value) {
        this.selectedIds = [];
        return;
      }

      this.selectedIds = this.value.split(',').map((i) => +i).filter((i) => !!i);
      this.selectedData = Common.getSelectedItems(this.selectedIds, this.options, { id: 'id', name: 'name' });
    },
    showModal() {
      if (this.disabled) {
        return;
      }
      this.modalShown = true;
    },
    closeModal() {
      this.modalShown = false;
    },

    clear() {
      this.select({ data: [] });
    },

    select(event) {
      this.selectedData = event?.data || [];
      this.selectedIds = this.selectedData.map(i => i.id);

      const ids = this.selectedIds.join(',');

      this.closeModal();
      this.$emit('change', ids);
      this.$emit('select', event);
    },
  },
};
</script>

<style scoped lang="scss">
.select-wrapper {
  position: relative;
}

.select {
  min-width: 350px;
}

.select:hover + .clear-btn:not(.hidden) {
  display: inline-block;
}

.clear-btn {
  position: absolute;
  top: 14px;
  right: 11px;
  z-index: 1;
  display: none;
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);

  &:not(.hidden):hover {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }
}
</style>
