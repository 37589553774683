<template>
  <a-modal
    :visible="is_visible"
    :closable="false"
    :maskClosable="false"
    :footer="null"
    width="60%"
     >
    <div class="text">您的{{ currentMonth }}月销售积分为<span style="font-weight: bold">{{ score }}</span>分 全国平均分为{{ country_avg_score }}分</div>
    <div class="text mg-20">
      全国排名{{ranking_num}}名(全国共{{all_contry_people_num}}人) <span v-if="rateList.includes(ranking_rate)">位于{{ranking_rate}}</span></div>
    <a-table
      style="margin: 20px auto 0 auto; width: 95%"
      :columns="columns"
      :data-source="tableData"
      :scroll="{ y: 450 }"
      :pagination="false" v-if="tableData.length > 0">
    </a-table>
    <div class="btn-part">
      <a-button class="back-btn" @click="goBack">返回</a-button>
    </div>
  </a-modal>
</template>

<script>
import { getHonorMonthInfo } from '../api/api';

const renderContent = (value, row) => {
  const obj = {
    children: value,
    attrs: {
      rowSpan: row.span,
    },
  };
  return obj;
};
export default {
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
    userId: {
      type: [String, Number],
      default: '',
    }
  },
  data() {
    const columns = [
      {
        title: '一级指标',
        dataIndex: 'firstTitle',
        key: 'firstTitle',
        customRender: renderContent,
        align: 'center',
      },
      {
        title: '二级指标',
        dataIndex: 'secondTitle',
        key: 'secondTitle',
        align: 'center',
      },
      {
        title: '你的得分',
        dataIndex: 'self_score',
        key: 'self_score',
        align: 'center',
      },
      {
        title: '全国平均分',
        dataIndex: 'country_avg_score',
        key: 'country_avg_score',
        align: 'center',
      },
    ];
    return {
      ranking_rate: '',
      score: '',
      ranking_num: '',
      all_contry_people_num: '',
      country_avg_score: '',
      sourceData: [],
      columns,
      rateList: ['前30%', '后30%']
    };
  },
  computed: {
    is_visible: {
      get() {
        return this.shown;
      },
      set(val) {
        this.$emit('visible', val);
      },
    },
    tableData() {
      const deepData = JSON.parse(JSON.stringify(this.sourceData));
      const source = deepData.reduce((acc, cur) => {
        const len = cur.child.length;
        const arr = cur.child.map((child, index) => ({
          ...child,
          firstTitle: cur.title,
          secondTitle: child.title,
          span: index === 0 ? len : 0,
          key: `${cur.key}-${index}`,
        }));
        return acc.concat(arr);
      }, []);
      return source;
    },
    currentMonth() {
      if(this.date) return new Date(this.date).getMonth() + 1;
      return ''
    }
  },
  watch: {
    date(val) {
      this.getData(val);
    },
  },
  methods: {
    getData(date) {
      getHonorMonthInfo({ date, uid: this.userId }).then((res) => {
        this.ranking_rate = res.data.ranking_rate;
        this.score = res.data.score;
        this.ranking_num = res.data.ranking_num;
        this.all_contry_people_num = res.data.all_contry_people_num;
        this.country_avg_score = res.data.country_avg_score;
        this.sourceData = res.data.list.map((item, index) => ({
          ...item,
          key: index,
        }));
      });
    },
    goBack() {
      this.is_visible = false;
    }
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 45px;
  background: #fff;
}
.text {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.mg-20 {
  margin-top: 10px;
}
.btn-part {
  margin-top: 30px;
  text-align: center;
}
.back-btn {
  width: 140px;
  background: #FF5A52;
  border-radius: 4px 4px 4px 4px;
  color: #fff;
}
</style>
