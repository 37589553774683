import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/JsDownload';


// 学员电子档案列表
export function getAnswerList(params) {
  return request({
    url: `/studentfiles/admin/electronicFile/electronicFileList`,
    method: 'get',
    params,
  });
}

// 学员电子档案列表-导出 excel
export function getAnswerListDownload(params) {
  let url = `/studentfiles/admin/electronicFile/electronicFileList`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 电子档案--信息维护信息
export function getStudentFile(params) {
  return request({
    url: `/studentfiles/admin/electronicFile/userInfoMaintenance`,
    method: 'get',
    params,
  });
}

// 修改用户学员档案信息
export function saveStudentFile(data) {
  return request({
    url: `/studentfiles/admin/electronicFile/updManagerBasicInfo`,
    method: 'post',
    data,
  });
}

// 学员档案--学习时间
export function getStudyList(params) {
  return request({
    url: `/studentfiles/admin/datalist/studytime`,
    method: 'get',
    params,
  });
}

// 学员档案--学习时间-导出 excel
export function getStudyListDownload(params) {
  let url = `/studentfiles/admin/datalist/studytime`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 学员档案--销售查询-Kimi
export function getSaleList(params) {
  return request({
    url: `/studentfiles/admin/datalist/seller`,
    method: 'get',
    params,
  });
}

// 学员档案--销售查询-Kimi-导出 excel
export function getSaleListDownload(params) {
  let url = `/studentfiles/admin/datalist/seller`;
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

