<template>
  <ListQueryForm />
</template>

<script>
import ListQueryForm from "./components/ListQueryForm.vue";

export default {
  name: "ListQuery",
  components: {
    ListQueryForm,
  },
};
</script>

<style scoped lang="scss">
</style>

