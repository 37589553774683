<template>
  <div class="sub-title">
    <img src="../assets/title/border-left.png" alt="" class="icon">
    <slot/>
    <img v-if="showEdit"
         src="../assets/title/edit-red.png"
         alt=""
         class="edit-icon"
         @click="$emit('on-edit')">
  </div>
</template>

<script>
export default {
  name: 'SubTitle',
  props: {
    showEdit: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
.sub-title {
  padding: 5px 0px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC, sans-serif;
  font-weight: bold;
  color: #333333;
  display: flex;
  align-items: center;

  .icon {
    width: 3px;
    height: 16px;
    margin-right: 8px;
  }

  .edit-icon {
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
