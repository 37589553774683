<template>
  <div class="card">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 16px;
  padding: 16px 20px;
  border-radius: 4px 4px 4px 4px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}
</style>
