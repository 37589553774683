<template>
  <div class="wrapper">
    <a-select ref="selectRef"
              v-model="post"
              placeholder="请选择岗位"
              :get-popup-container="getContainer"
              :allow-clear="true"
              :disabled="disabled"
              class="select mr-20"
              @change="onPostChange"
              @focus="$emit('focus')"
              @blur="$emit('blur')">
      <a-select-option v-for="(item) in list"
                       :key="item.id"
                       :value="item.id">
        <a-tooltip :title="item.name" placement="left">{{ item.name }}</a-tooltip>
      </a-select-option>
    </a-select>
    <a-select ref="selectRef"
              v-model="level"
              :placeholder="levelPlaceholder"
              :get-popup-container="getContainer"
              :disabled="!levelList || !levelList.length || disabled"
              :allow-clear="true"
              class="select"
              @change="onLevelChange"
              @focus="$emit('focus')"
              @blur="$emit('blur')">
      <a-select-option v-for="(item) in levelList"
                       :key="item.id"
                       :value="item.id">
        <a-tooltip :title="item.levelName" placement="left">
          {{ item.levelName }}
        </a-tooltip>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectPostLevel',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { // value 的数据结构：[`${postId},${levelId},${levelId}`]
      type: Array,
      default: () => [],
    },
    mode: { // 暂不支持多选
      type: String,
      default: 'single',
      validator: (val) => ['single', 'multiple'].includes(val),
    },
    disabled: { type: Boolean, default: false },
    fixedTags: { type: Array, default: () => ['a-form-model-item', 'AFormModelItem'] },
  },
  computed: {
    ...mapState({
      list: state => state.Selection.postLevelList,
    }),
    levelPlaceholder() {
      return this.levelList?.length ? '请选择等级' : '当前岗位无等级';
    },
    getContainer() {
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag
            || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent.$el : null) || this.$root?.$el || document.body || null;
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this && this.post !== null) {
          this.setPost(val);

          const [post] = this.list.filter((i) => i.id === this.post) || [];
          this.levelList = post?.level || [];
          this.level = undefined;
        }
        if (this && this.level !== null) {
          this.setLevel(val);
        }
      },
    },
    list() {
      const [post] = this.list.filter((i) => i.id === this.post) || [];
      this.levelList = post?.level || [];
    },
  },
  data() {
    return {
      post: undefined,
      level: undefined,

      levelList: [],
    };
  },
  created() {
    this.$store.dispatch('Selection/getPostLevelList');
  },
  methods: {
    setPost(value) {
      value = value || this.value;
      const posts = (value || []).map((i) => +i.split(',')[0]);

      if (this.mode === 'single') {
        this.post = posts[0] || undefined;
      }
    },
    setLevel(value) {
      value = value || this.value;
      const levels = (value || []).map((i) => {
        const levelStr = (i || '').split(',');
        levelStr.shift();
        return levelStr.map((l) => +l).filter((l) => !!l);
      }) || [];

      if (this.mode === 'single') {
        this.level = levels[0]?.length && levels[0][0] ? levels[0][0] : undefined;
      }
    },

    onPostChange() {
      const [post] = this.list.filter((i) => i.id === this.post) || [];
      this.levelList = post?.level || [];
      this.level = undefined;

      this.onChange();
    },
    onLevelChange() {
      this.onChange();
    },

    onChange() {
      const evt = [];

      if (this.mode === 'single') {
        evt.push([this.post, this.level].join(','));
      }

      this.$emit('change', evt);
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  width: calc(50% - 10px);
}
</style>
