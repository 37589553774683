<template>
  <Card>
    <Title>工作信息</Title>
    <!-- 红旗工作经历 -->
    <SubTitle>红旗工作经历</SubTitle>
    <div class="steps">
      <a-icon @click="workPage -= 1" v-if="workPage > 0" class="double my-btn step-prev" type="double-left" />
      <div class="steps-center">
        <div class="item" v-for="(item, index) in workExperienceArr" :key="index">
          <div class="step">
            <div class="date">{{ item.dateTime }}</div>
            <div class="center">
              <div class="line" :class="index === 0 && 'hid'" />
              <div class="ball" :class="workPage === 0 && index === 0 && 'red'" />
              <div class="line" :class="index === workExperienceArr.length - 1 && 'hid'" />
            </div>
            <div class="desc">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <a-icon @click="workPage += 1" v-if="workPage < workExperience.length / 4 - 1" class="double my-btn step-next" type="double-right" />
    </div>
    <!-- 汽车行业经历 -->
    <SubTitle :showEdit="!userId" @on-edit="show = true">汽车行业经历</SubTitle>
    <!-- 汽车行业经历 -->
    <div class="trade">
      <div v-if="enterDate" class="enter-date">进入汽车行业时间：{{ enterDate }}</div>
      <div v-else class="enter-date">无汽车行业经验</div>
      <!-- 豪华品牌经验 -->
      <div>
        <div
          v-if="carExperience.luxury && carExperience.luxury.length"
          class="caption"
        >
          豪华品牌经验
        </div>
        <div class="options">
          <div
            class="option"
            v-for="item in carExperience.luxury"
            :key="item.id"
          >
            <span>{{ item.time }}</span>
            <span>{{ item.car_name }}</span>
            <span>{{ item.post_name }}</span>
          </div>
        </div>
      </div>
      <!-- 新能源经验 -->
      <div>
        <div
          v-if="carExperience.new_energy && carExperience.new_energy.length"
          class="caption"
        >
          新能源经验
        </div>
        <div class="options">
          <div
            class="option"
            v-for="item in carExperience.new_energy"
            :key="item.id"
          >
            <span>{{ item.time }}</span>
            <span>{{ item.car_name }}</span>
            <span>{{ item.post_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <a-modal
      title="汽车行业经历"
      okText="提交"
      :visible="show"
      :width="478"
    >
      <template slot="footer">
        <div class="m-footer">
          <a-button class="cancel my-btn" key="back" @click="show = false">
          取消
          </a-button>
          <a-button class="ok my-btn" key="submit" type="primary" :loading="loading" @click="onSubmit">
            提交
          </a-button>
        </div>
      </template>
      <template slot="closeIcon">
        <a-icon @click="show = false" type="close-circle" :style="{ fontSize: '20px', color: '#999' }" />
      </template>
      <div class="block">
        <div class="label">您进入汽车行业的时间是</div>
        <a-month-picker
          size="large"
          class="enter-date"
          :format="monthFormat"
          :default-value="newEnterDate ? moment(newEnterDate, monthFormat) : ''"
          @change="confirmDate"
        >
          <a-icon slot="suffixIcon" type="clock-circle" />
        </a-month-picker>
        <!-- 豪华品牌经验 -->
        <div class="label">
          <span>您的豪华品牌经验</span>
          <div @click="addLuxury" class="my-btn">＋ 添加</div>
        </div>
        <div
          @click="current = item"
          class="option"
          v-for="(item, index) in luxury"
          :key="'luxury' + index"
        >
          <div class="item">
            <span class="left">从业品牌</span>
            <a-select size="large" class="input" v-model="item.brand">
              <a-select-option
                :value="item.id"
                v-for="item in brandList"
                :key="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="left">从业时间</span>
            <a-range-picker
              size="large"
              class="input"
              :mode="mode"
              :format="monthFormat"
              :value="[
                item.start_day ? moment(item.start_day, monthFormat) : null,
                item.end_day ? moment(item.end_day, monthFormat) : null,
              ]"
              @panelChange="confirmRange"
              separator="至"
            >
              <a-icon slot="suffixIcon" type="clock-circle" />
            </a-range-picker>
          </div>
          <div class="item">
            <span class="left">任职岗位</span>
            <a-input size="large" class="input" v-model="item.post" />
          </div>
          <a-icon @click="del(index, 'luxury')" class="delete my-btn" theme="filled" type="close-circle" />
        </div>
        <!-- 新能源经验 -->
        <div class="label">
          <span>您的新能源经验</span>
          <div @click="addEnergy" class="my-btn">＋ 添加</div>
        </div>
        <div
          @click="current = item"
          class="option"
          v-for="(item, index) in newEnergy"
          :key="'new' + index"
        >
          <div class="item">
            <span class="left">从业品牌</span>
            <a-input size="large" class="input" v-model="item.brand" />
          </div>
          <div class="item">
            <span class="left">从业时间</span>
            <a-range-picker
              size="large"
              class="input"
              :mode="mode"
              :format="monthFormat"
              :value="[
                item.start_day ? moment(item.start_day, monthFormat) : null,
                item.end_day ? moment(item.end_day, monthFormat) : null,
              ]"
              @panelChange="confirmRange"
              separator="至"
            >
              <a-icon slot="suffixIcon" type="clock-circle" />
            </a-range-picker>
          </div>
          <div class="item">
            <span class="left">任职岗位</span>
            <a-input size="large" class="input" v-model="item.post" />
          </div>
          <a-icon @click="del(index, 'newEnergy')" class="delete my-btn" theme="filled" type="close-circle" />
        </div>
      </div>
    </a-modal>
  </Card>
</template>

<script>
import moment from "moment";
import Card from "./Card.vue";
import Title from "./Title.vue";
import SubTitle from "./SubTitle.vue";
import {
  getBrand,
} from "../api/api";
import {
  getWorkExperienceManager,
  getCarExperienceManager,
  editCarExperienceManager,
} from '../api/managerApi';

export default {
  name: "Work",
  components: {
    Card,
    Title,
    SubTitle,
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      workPage: 0, // 红旗工作经历页面索引
      workExperience: [], // 红旗工作经历
      carExperience: {}, // 汽车行业经历
      enterDate: "", // 进入汽车行业时间
      newEnterDate: "", // 进入汽车行业时间(form)
      newEnergy: [], // 新能源经验
      luxury: [], // 豪华品牌经验
      show: false, // 显示弹窗
      brandList: [], // 品牌列表
      current: 0, // 选中经验
      loading: false,
      monthFormat: "YYYY/MM",
      mode: ["month", "month"],
    };
  },

  computed: {
    workExperienceArr() {
      return [...this.workExperience].splice(this.workPage * 4, 4);
    }
  },

  created() {
    this.getWorkExperience();
    this.getCarExperience();
    this.getBrand();
  },

  methods: {
    moment,
    // 获取红旗工作经历
    async getWorkExperience() {
      const data = await getWorkExperienceManager({ uid: this.userId });
      this.workExperience = data?.data?.reverse();
    },

    // 获取汽车行业经历
    async getCarExperience() {
      const { data, meta } = await getCarExperienceManager({ uid: this.userId }) || {};
      this.carExperience = data;
      this.luxury = [...data.luxury];
      this.newEnergy = [...data.new_energy];
      this.newEnterDate = meta.in_automobile_industry;
      this.enterDate = meta.in_automobile_industry_day;
    },

    // 获取品牌列表
    async getBrand() {
      const { data } = await getBrand();
      this.brandList = data;
    },

    // 添加豪华品牌
    addLuxury() {
      this.luxury.push({
        id: 0,
        start_day: "",
        end_day: "",
        brand: "",
        post: "",
      });
    },

    // 添加新能源
    addEnergy() {
      this.newEnergy.push({
        id: 0,
        start_day: "",
        end_day: "",
        brand: "",
        post: "",
      });
    },

    // 删除
    del(index, type) {
      switch (type) {
        case "luxury":
          this.luxury.splice(index, 1);
          break;

        case "newEnergy":
          this.newEnergy.splice(index, 1);
          break;

        default:
          break;
      }
    },

    // 选择时间(进入汽车行业时间)
    confirmDate(date, dateString) {
      this.newEnterDate = dateString;
    },

    // 选择时间(从业时间)
    confirmRange(value) {
      const [start, end] = value;
      const startDate = start._d;
      const endDate = end._d;
      this.current.start_day = `${startDate.getFullYear()}/${
        startDate.getMonth() + 1
      }`;
      this.current.end_day = `${endDate.getFullYear()}/${
        endDate.getMonth() + 1
      }`;
    },

    // 提交
    async onSubmit() {
      // 验证用户
      this.validate(async () => {
        this.loading = true;
        try {
          await editCarExperienceManager({
            uid: this.userId,
            in_automobile_industry_day: this.newEnterDate,
            new_energy: JSON.stringify(this.newEnergy),
            luxury: JSON.stringify(this.luxury),
          });
          this.show = false;
          this.getCarExperience();
          this.$message.success('修改成功');
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      });
    },

    // 表单验证
    validate(callback) {
      try {
        this.newEnergy.forEach((item) => {
          if (!item.brand) {
            throw new Error('请输入品牌');
          }
          if (!item.post) {
            throw new Error('请输入岗位');
          }
          if (!item.start_day) {
            throw new Error('请选择开始日期');
          }
          if (!item.end_day) {
            throw new Error('请选择结束时间');
          }
        })
        this.luxury.forEach((item) => {
          if (!item.brand) {
            throw new Error('请输入品牌');
          }
          if (!item.post) {
            throw new Error('请输入岗位');
          }
          if (!item.start_day) {
            throw new Error('请选择开始日期');
          }
          if (!item.end_day) {
            throw new Error('请选择结束时间');
          }
        })
        callback();
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.m-footer {
  display: flex;
  justify-content: center;
  padding: 18px 0;

  .cancel {
    width: 130px;
    height: 36px;
    border-radius: 50px;
    border: 1px solid #FF5A52;
    font-size: 18px;
    color: #FF5A52;
    margin-right: 15px;
  }

  .ok {
    width: 130px;
    height: 36px;
    background: #FF5A52;
    border-radius: 50px;
    font-size: 18px;
    color: #fff;
    border: 0;
  }
}

.block {
  .label {
    font-size: 16px;
    color: #333;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    div {
      width: 102px;
      line-height: 36px;
      font-size: 18px;
      color: #ff5a52;
      border: 1px solid #ff5a52;
      border-radius: 50px;
      text-align: center;
      cursor: pointer;
    }
  }

  .enter-date {
    width: 100%;
    margin-bottom: 16px;
  }

  .option {
    padding: 16px 10px;
    margin-bottom: 16px;
    background: #F7F7F7;
    position: relative;
  }

  .item {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .left {
      font-size: 16px;
      color: #666;
      margin-right: 20px;
    }

    .input {
      flex: 1;
    }
  }

  .delete {
    display: block;
    font-size: 14px;
    color: #5b5b5b;
    position: absolute;
    right: -2px;
    top: -2px;
  }
}

.trade {
  margin-top: 10px;

  .enter-date,
  .caption {
    font-size: 14px;
    color: #333;
    line-height: 16px;
  }

  .caption {
    margin: 10px 0;
  }

  .options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .option {
    width: calc(50% - 8px);
    height: 36px;
    padding: 0 20px;
    background: #fafafa;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
      flex: 1;
      font-size: 12px;
      color: #333;
      font-family: PingFang SC-Regular, PingFang SC;
    }

    span:nth-child(2) {
      text-align: center;
    }

    span:last-child {
      text-align: right;
    }
  }
}

.steps {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 60px;
  margin-bottom: 10px;

  .step-prev {
    position: absolute;
    top: 54px;
    left: 40px;
  }

  .step-next {
    position: absolute;
    top: 54px;
    right: 40px;
  }

  .double {
    color: #999;
    font-size: 10px;
    cursor: pointer;
  }

  .steps-center {
    flex: 1;
    display: flex;
  }

  .item {
    width: 25%;
    flex-shrink: 0;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;

    .date {
      font-size: 14px;
      color: #666;
      line-height: 16px;
    }

    .desc {
      font-size: 12px;
      color: #333;
      line-height: 16px;
      margin: 0 10px;
      text-align: center;
    }

    .center {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 18px 0;

      .line {
        flex: 1;
        height: 2px;
        background: #efeded;
      }

      .hid {
        opacity: 0;
      }

      .last {
        background: linear-gradient(
          180deg,
          #efeded 0%,
          rgba(239, 237, 237, 0) 100%
        );
      }

      .ball {
        width: 10px;
        height: 10px;
        background: #efeded;
        border-radius: 50%;
        margin: 0 10px;
      }

      .red {
        background-color: #e50211;
      }
    }
  }
}

.my-btn:active {
  opacity: 0.5;
}
</style>
