<template>
  <div class="chart" :style="{height}">
    <div ref="chart" :style="{ height: height, width: width }" />
  </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  name: 'ColumnChart',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '320px',
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    chartOption: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'canvas',
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    if (this.autoResize) {
      window.addEventListener('resize', this.resizeHandler);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.resizeHandler);
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    resizeHandler() {
      this.chart.resize();
    },
    initChart() {
      this.chart = echarts.init(this.$refs.chart);
      this.chart.setOption(this.chartOption);
      this.chart.on('click', this.handleClick);
    },
    handleClick(params) {
      console.log(params);
      this.$emit('click', params);
    },
    setOptions(option) {
      this.clearChart();
      this.resizeHandler();
      if (this.chart) {
        this.chart.setOption(option);
      }
    },
    refresh() {
      this.setOptions(this.chartOption);
    },
    clearChart() {
      if (this.chart) {
        this.chart.clear();
      }
    },
  },

};
</script>

<style lang="less" scoped>
.chart{
  width: 100%;
  height: 100%;
}
</style>
