import request from '@/utils/Request';


// 获取学历列表
export function getDegreeList(data) {
  return request({
    url: '/public/select/degree',
    method: 'GET',
    data,
  });
}

// 获取品牌列表
export function getBrand() {
  return request({
    url: '/public/select/carBrand',
    method: 'GET',
  });
}

// 获取任职岗位
export function getPost() {
  return request({
    url: '/manage/select/jobs',
    method: 'GET',
  });
}

// e旗学app下载二维码
export function getCodeUrl(params) {
  return request({
    url: '/public/conf/index',
    method: 'get',
    params,
  });
}

// 荣耀单月数据详情
export function getHonorMonthInfo(params) {
  return request({
    url: `/studentfiles/admin/studentfiles/honorStatisticsInfoMonth`,
    method: 'get',
    params
  });
}
