<template>
  <a-form-model ref="formModalRef"
                :model="form"
                label-align="right"
                layout="inline"
                :label-col="{span: 8}"
                :wrapper-col="{span: 16}"
                class="pd-card query-form">
    <div class="title">基础信息</div>

    <a-form-model-item label="学员姓名" prop="name" class="form-item">
      <a-input v-model="form.name" placeholder="请输入学员姓名"/>
    </a-form-model-item>

    <a-form-model-item label="身份证号" prop="idcard" class="form-item">
      <a-input v-model="form.idcard" placeholder="请输入身份证号"/>
    </a-form-model-item>

    <a-form-model-item label="手机号" prop="mobile" class="form-item">
      <a-input v-model="form.mobile" placeholder="请输入手机号"/>
    </a-form-model-item>

    <a-form-model-item label="性别" prop="gender" class="form-item">
      <SelectGender v-model="form.gender"/>
    </a-form-model-item>

    <a-form-model-item label="学历" prop="degree_id" class="form-item">
      <SelectDegree v-model="form.degree_id"/>
    </a-form-model-item>

    <a-form-model-item label="毕业院校" prop="graduate_school" class="form-item">
      <a-input v-model="form.graduate_school" placeholder="请输入毕业院校"/>
    </a-form-model-item>

    <a-form-model-item label="年龄范围"
                       prop="age_min"
                       :label-col="{span: 6}"
                       :wrapper-col="{span: 18}"
                       class="form-item range-outer">
      <a-input-number v-model="form.age_min"
                      :min="0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      placeholder="最小年龄"/>
      <span class="ml-20 mr-20">至</span>
      <a-input-number v-model="form.age_max"
                      :min="form.age_min || 0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      placeholder="最大年龄"/>
    </a-form-model-item>

    <div class="mt-30 title">工作信息</div>

    <a-form-model-item label="选择岗位及认证等级" prop="student_name" class="form-item">
      <SelectPostLevel v-model="form.post"/>
    </a-form-model-item>

    <a-form-model-item label="选择组织机构" prop="student_name" class="form-item">
      <SelectOrg v-model="form.org_ids"/>
    </a-form-model-item>

    <a-form-model-item label="红旗工龄" prop="student_name" class="form-item">
      <a-input-number v-model="form.job_time_min"
                      :min="0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      placeholder="最小工龄"/>
      <span class="ml-20 mr-20">至</span>
      <a-input-number v-model="form.job_time_max"
                      :min="form.job_time_min || 0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      placeholder="最大工龄"/>
    </a-form-model-item>

    <a-form-model-item label="豪华品牌经验" prop="student_name" class="form-item">
      <SelectAssertion v-model="form.is_luxury_exp"
                       :assertion-map="{yes: 2, no: 1}"
                       placeholder="是否有豪华品牌经验"/>
    </a-form-model-item>

    <a-form-model-item label="进入汽车行业年限" prop="student_name" class="form-item">
      <a-input-number v-model="form.car_job_length_min"
                      :min="0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      placeholder="最小年限"/>
      <span class="ml-20 mr-20">至</span>
      <a-input-number v-model="form.car_job_length_max"
                      :min="form.car_job_length_min || 0"
                      :formatter="value => `${value}`.replace(/\.*/g, '')"
                      :parser="value => `${value}`.replace(/\.*/g, '')"
                      placeholder="最大年限"/>
    </a-form-model-item>

    <a-form-model-item label="新能源经验" prop="student_name" class="form-item">
      <SelectAssertion v-model="form.is_new_energy"
                       :assertion-map="{yes: 2, no: 1}"
                       placeholder="是否有新能源经验"/>
    </a-form-model-item>

    <div class="full-width flex mt-30 btn-wrapper">
      <a-button type="primary" class="mr-20" @click="onSearch">查询</a-button>
      <a-button @click="onReset">重置</a-button>
    </div>
  </a-form-model>
</template>

<script>
import SelectGender from '@/components/selection/SelectGender.vue';
import SelectDegree from '@/components/selection/SelectDegree.vue';
import SelectPostLevel from '@/components/selection/SelectPostLevel.vue';
import SelectAssertion from '@/components/selection/SelectAssertion.vue';
import SelectOrg from '@/components/selection/SelectOrg.vue';

const form = {
  // 基础信息
  name: '',
  idcard: '',
  mobile: '',
  gender: '',
  degree_id: '',
  graduate_school: '',
  age_min: undefined,
  age_max: undefined,

  // 工作信息
  post: undefined,
  post_id: undefined,
  level: undefined,

  org_ids: '',

  job_time_min: undefined,
  job_time_max: undefined,
  is_luxury_exp: undefined,
  car_job_length_min: undefined,
  car_job_length_max: undefined,
  is_new_energy: undefined,
};

export default {
  name: 'ListQueryForm',
  components: {
    SelectGender,
    SelectDegree,
    SelectPostLevel,
    SelectAssertion,
    SelectOrg,
  },
  watch: {
    'form.mobile'(val, oldVal) {
      if (!/^[0-9]{0,11}$/g.test(val)) {
        this.form.mobile = oldVal;
      }
    },
    'form.idcard'(val, oldVal) {
      if (!/^[0-9xX]*$/g.test(val)) {
        this.form.idcard = oldVal;
      }
    },
  },
  data() {
    return {
      form: { ...form },
    };
  },
  created() {
    const query = this.$route.query || {};
    Object.keys(query).forEach((i) => {
      if (!query[i]) {
        return;
      }
      const numbered = [
        'mobile',
        'degree_id',
        'age_min',
        'age_max',
        'job_time_min',
        'job_time_max',
        'is_luxury_exp',
        'car_job_length_min',
        'car_job_length_max',
        'is_new_energy',
      ];
      if (numbered.includes(i)) {
        this.form[i] = +query[i];
      } else if (i === 'post') {
        this.form[i] = [query[i]];
      } else {
        this.form[i] = query[i];
      }
    });
  },
  methods: {
    onSearch() {
      if (Object.keys(this.form).every((i) => !this.form[i])) {
        this.$message.info('请输入查询条件');
        return;
      }

      const params = { ...this.form };
      [params.post_id = ''] = (params.post && params.post[0] || '').split(',');
      [, params.level = ''] = (params.post && params.post[0] || '').split(',');
      delete params.post;

      this.$emit('on-search', params);

      const query = {};
      Object.keys(this.form).forEach((i) => {
        if (i === 'post') {
          query[i] = this.form[i] ? (this.form[i][0] || '') : '';
        } else {
          query[i] = this.form[i] || '';
        }
      });
      query.post_id = params.post_id;
      query.level = params.level;

      this.$router.push({
        path: '/index/studentFiles/list/result',
        query,
      });
    },
    onReset() {
      this.form = { ...form };

      const params = { ...this.form };
      delete params.post;

      this.$emit('on-reset', params);
    },
  },
};
</script>

<style scoped lang="scss">
.query-form {
  // position: absolute;
  // top: 0;
  // right: 0;
  // left: 0;
  // z-index: 9;
  min-height: 100%;
  background-color: #FFFFFF;
}

.form-item {
  width: 40%;
  margin-bottom: 5px;

  &.range-outer {
    width: 54%;
  }
}

.title {
  width: 90%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.btn-wrapper {
  padding-bottom: 30px;
}
</style>
