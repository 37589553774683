<template>
  <div class="student-files-list">
    <div class="flex space-between ph-20 mb-10">
      <div>
        <span>共{{ pagination.total }}条查询结果</span>
      </div>
      <div>
        <a-button @click="back">修改筛选条件</a-button>
        <a-button type="primary" :loading="downloading" @click="exportExcel">导出查询结果</a-button>
      </div>
    </div>

    <div class="ph-20">
      <a-table :columns="columns"
                :data-source="data"
                :pagination="false"
                :loading="loading"
                row-key="id"
                bordered
                class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="edit(record)">信息维护</a-button>
          <a-button type="link" @click="viewFiles(record)">档案详情</a-button>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <StudentFileModal ref="studentFileModalRef"/>
  </div>
</template>

<script>
import {
  getAnswerList,
  getAnswerListDownload,
} from '@/api/studentFiles';

import Pagination, { getPagination, updatePagination } from '@/components/Pagination.vue';
import StudentFileModal from './components/StudentFileModal.vue';

const columns = [
  {
    width: '80px',
    align: 'center',
    dataIndex: 'truename',
    key: 'truename',
    title: '姓名',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'idcard',
    key: 'idcard',
    title: '身份证号',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'mobile',
    key: 'mobile',
    title: '电话',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'post_name',
    key: 'post_name',
    title: '岗位',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'org_name',
    key: 'org_name',
    title: '体验中心',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  components: {
    Pagination,
    StudentFileModal,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      params: {},
      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.params = this.$route.query || {};
    this.onSearch();
  },
  methods: {
    back() {
      // this.$router.back();
      this.$router.push({
        path: '/index/studentFiles/list/query',
        query: {
          ...(this.$route.query || {})
        }
      });
    },
    onQuerySearch(evt) {
      this.params = evt || {};
      this.onSearch();
      this.hideQuery();
    },
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      this.params.page = page || 1;
      this.params.per_page = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getAnswerList({
        ...this.params,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },

    exportExcel() {
      if (this.downloading) {
        this.$message.warn('正在导出其它文件，请稍候');
        return;
      }
      this.downloading = true;
      getAnswerListDownload({
        ...this.params,
        is_download: 1,
      }).then(() => {
        this.$message.success('导出成功');
        return true;
      }).catch(() => {
        this.$message.error('导出失败');
        return false;
      }).finally(() => {
        this.downloading = false;
      });
    },

    edit(record) {
      if (this.$refs.studentFileModalRef?.show) {
        this.$refs.studentFileModalRef.show({ ...record });
      }
    },
    viewFiles(record) {
      this.$router.push({
        path: '/index/studentFiles/list/file',
        query: {
          userId: record.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.student-files-list {
  position: relative;
}
</style>
